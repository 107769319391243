body {
  background: url(/img/la.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
a {
  color: #57C3DA;
}
.btn-primary {
  background-color: #57C3DA;
}
.btn-primary:hover {
  background-color: #51B4C9;
}
.navbar-transparent {
  background-color: transparent;
  border: none;
  padding: 20px 0;
}
.register-headline {
  text-align: center;
  color: #fff;
  font-size: 1.7em;
  margin-bottom: 30px;
}
.panel-dark {
  background-color: rgba(68, 68, 68, 0.9);
  border: none;
  border-radius: 0;
  padding-top: 20px;
}
.alert-danger {
  background-color: #fff !important;
}
.login .checkbox {
  color: #efefef;
}
